<template>
  <div id="about_template">
    <loader ref="loader" type="page"></loader>
    <div id="page-content" v-bind:class="[pageContentVisibilityState]">
      <page-header title="About Me"></page-header>
      <!--[START] Intro Section-->
      <section class="intro-section" v-if="aboutIntro">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 order-2 order-md-1 order-lg-1 text-left">
              <h2 class="bio-short desktop NexaText-Regular">{{aboutIntro}}</h2>
              <div class="NexaText-Light" v-html="aboutBio"></div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 order-1 order-md-2 order-lg-2">
              <h2 class="bio-short mobile NexaText-Bold">{{aboutIntro}}</h2>
              <img v-bind:src="aboutImage.uncroppedImage.srcWebp" v-bind:srcset="aboutImage.uncroppedImage.srcsetWebp" alt="Image of Larry Herb">
            </div>
          </div>
        </div>
      </section>
      <!--[END] Intro Section-->
      <!--[START] Education Section-->
      <section class="education-section">
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <h1 class="Nexa-Bold section-header">Education</h1>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-sm-12 col-md-6 col-lg-6"  v-for="(institution, key) in education" v-bind:key="key">
              <div class="education-item">
                <div class="logo-container">
                  <img v-bind:src="institution.logo[0].uncroppedImage.src" v-bind:srcset="institution.logo[0].uncroppedImage.srcset" v-bind:alt="institution.school+' logo'">
                </div>
                <label class="name NexaText-Regular">{{institution.school}}</label>
                <label class="detail NexaText-Light">{{institution.timeline}}</label>
                <label class="detail NexaText-Light">{{institution.degree}}</label>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--[END] Education Section-->
      <!--[START] Experience Section-->
      <section class="experience-section">
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <h1 class="Nexa-Bold section-header">Professional Experience</h1>
            </div>
          </div>
          <!--[START] Experience Item-->
          <div class="experience-item" v-for="(experience, key) in workExperience" v-bind:key="key">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-3 text-right">
                <div class="logo-container">
                  <img v-bind:src="experience.logo[0].uncroppedImage.srcWebp" v-bind:srcset="experience.logo[0].uncroppedImage.srcsetWebp" v-bind:alt="experience.employer+' logo'">
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-9">
                <label class="company NexaText-Bold">{{experience.employer}}</label>
                <label class="detail NexaText-Regular">{{experience.role}}</label><br />
                <label class="detail NexaText-Regular">{{experience.location}}</label>
                <div class="NexaText-Light" v-html="experience.jobDescription"></div>
              </div>
            </div>
          </div>
          <!--[END] Experience Item-->
          <div class="footnote">
            <div class="row text-center">
              <div class="col NexaText-Light" v-html="minorWorkExperience"></div>
            </div>
          </div>
        </div>
      </section>
      <!--[END] Experience Section-->
      <!--[START] Skillset Section-->
      <section class="skillset-section">
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <h1 class="Nexa-Bold section-header">Skillset Experience</h1>
            </div>
          </div>
          <!--[START] Skillset Item-->
          <section class="skillset-group" v-for="(group, key) in skillSetGroups" v-bind:key="key">
            <div class="row">
              <div class="col">
                <h2 class="Nexa-Regular">{{group.groupName}}</h2>
              </div>
            </div>
            <div class="row text-center">
              <div class="col-sm-12 col-md-6 col-lg-3" v-for="(skill, key) in group.skill" v-bind:key="key">
                <label class="skillset NexaText-Regular">{{skill.skill}}</label>
              </div>
            </div>
          </section>
          <!--[END] Skillset Item-->
        </div>
      </section>
      <!--[END] Skillset Section-->
    </div>
  </div>
</template>
<script>
  import Loader from '@/components/Loader.vue'
  import { gql } from '@/gql/views/about.js'
  import Api from '@/api.js'
  import Seomatic from '@/seomatic.js'
  const PageHeader = () => import(/* webpackChunkName: "page-header" */ '@/components/PageHeader.vue')

  export default {
    name: 'About',
    metaInfo () {
      return {
          title: this.metaData.title,
          meta: this.metaData.tags
      }
    },
    components: {
      PageHeader,
      Loader
    },
    data () {
      return {
        aboutIntro: '',
        aboutBio: '',
        aboutImage: null,
        education: [],
        workExperience: [],
        skillSetGroups: [],
        minorWorkExperience: '',
        pageContentVisibilityState: '',
        metaData: {}
      }
    },
    methods: {
    loadPage: function () {
        // Load the Page Function and Get Data
        const api = new Api({
          callback: (response) => {
            // Response Gotten
            if (response.data.entry) {
              var entry = response.data.entry
              this.aboutIntro = entry.aboutIntro
              this.aboutBio = entry.aboutBio
              this.education = entry.education
              this.workExperience = entry.workExperience
              this.minorWorkExperience = entry.minorWorkExperience
              this.skillSetGroups = entry.skillSetGroups
              this.aboutImage = entry.aboutImage[0]
              const seomatic = new Seomatic()
              this.metaData = seomatic.setMetaData(entry.seomatic)
            }
            this.$router.onReady(() => {
              this.$refs.loader.stopAnimation()
              this.pageContentVisibilityState = 'active'
            })
          },
          query: gql,
          variables: {
            preview: {
              token: this.$route.query.token
            }
          }
        })
        console.log(api)
      }
    },
    mounted () {
      this.$router.onReady(() => {
        this.$refs.loader.startAnimation()
        this.loadPage()
      })
    }
}
</script>
<style lang="scss">
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";
  #page-content{
    display: none;
    opacity: 0.0;
  }
  #page-content.active{
    display: block;
    opacity: 1.0;
    animation-delay: 1s;
    animation: fadeIn ease 1s;
  }
  @keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  #about_template{
    .section-header{
      text-transform: uppercase;
      letter-spacing: 2.0px;
    }
    .intro-section{
      padding-bottom: 25px;
      h2{
        font-size:18px;
        padding-bottom: 15px;
        line-height: 25px;
      }
      p{
        font-size: 15px;
        line-height: 25px;
      }
      img{
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
      }
      .bio-short.desktop{
        display: none;
      }
      .bio-short.mobile{
        display: block;
      }
    }
    .education-section{
      background-color: black;
      padding-top: 30px;
      padding-bottom: 30px;
      color: #fff;
      h1{
        font-size: 25px;
        padding-bottom: 20px;
        color: #ccc;
      }
      .education-item{
        img{
          width: 75%;
          padding-top: 20px;
          padding-bottom: 20px;
        }
        .name{
          display: block;
          color: #fff;
          font-size: 18px;
        }
        .detail{
          display: block;
          color: #ccc;
          font-size: 15px;
          line-height: 15px;

        }
      }
    }
    .experience-section{
      display: block;
      padding-top: 30px;
      padding-bottom: 30px;
      h1{
        font-size: 25px;
      }
      .experience-item{
        padding-bottom: 15px;
        padding-top: 15px;
        .logo-container{
          padding-left: 30px;
          padding-right: 30px;
          text-align: center;
          height: 15
          0px;
          margin-bottom: 30px;
          img{
            height: 100%;
            width: 100%;
          }
        }
        .company{
          display: block;
          font-weight: bold;
          font-size: 18px;
        }
        .detail{
          color: #666;
          font-size: 15px;
        }
        p, ul, li{
          font-size: 15px;
          line-height: 25px;
        }
        ul,li{
          padding-left: 15px;

        }
      }
      .footnote{
        font-size: 13px;
        color: #666;
      }
    }
    .skillset-section{
      background-color: #f0f0f0;
      display: block;
      padding-top: 30px;
      padding-bottom: 30px;
      h1{
        font-size: 25px;
      }
      .skillset-group{
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        h2{
          color: #666666;
          text-align: center;
          font-size: 20px;
          padding-top: 15px;
          padding-bottom: 15px;
          border-bottom: #ccc 1px solid;
          text-transform: uppercase;
          letter-spacing: 1.5px;
        }
        .skillset{
          color: #000000;
          font-size:16px;
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }
  }

  @include media-breakpoint-up(xs) {
  }
  @include media-breakpoint-up(sm) {
  }
  @include media-breakpoint-up(md) {
  }
  @include media-breakpoint-down(md) {
  }
  @include media-breakpoint-up(lg) {
    #about_template{
      h1{
        font-size:2.5rem;
      }
      .intro-section{
        padding-bottom: 25px;
        h2{
          font-size:18px;
        }
        img{
          padding-top: 5px;
          padding-bottom: 5px;
        }
        .bio-short.mobile{
          display: none;
        }
        .bio-short.desktop{
          display: block;
        }
      }
      .education-section{
        /* */
        padding-top: 50px;
        padding-bottom: 50px;
        h1{
          /* */
        }
        .education-item{
          .logo-container{
            height: 200px;
          }
          img{
            width:auto;
            height: 100%;
          }
          .name{
            /* */
          }
          .detail{
            /* */

          }
        }
      }
      .experience-section{
        padding-top: 50px;
        padding-bottom: 50px;
        h1{
          padding-bottom: 35px;
        }
        .experience-item{
          .company{
          }
          .detail{
          }
          p, ul, li{
          }
          ul,li{
          }
        }
        .footnote{
        }
      }
      .skillset-section{
        padding-top: 50px;
        padding-bottom: 50px;
        h1{
        }
        .skillset-group{
          h2{
            padding-bottom: 25px;
          }
          .skillset{
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
</style>
