export const gql = `
query{
  entry(
    section:"about"
  ){
    id
    title
    ... on about_about_Entry{
      aboutIntro
      aboutBio
      aboutImage {
        ... on about_Asset{
          title
          size
          filename
          url
          kind
          uncroppedImage{
            srcset
            src
            srcWebp
            srcsetWebp
            placeholderSilhouette
            placeholderImage
            placeholderWidth
            placeholderHeight
            placeholderBox
            placeholderSvg
          }
        }
      }
      education{
        ... on education_BlockType{
          school
          logo {
            ... on about_Asset{
              title
              size
              filename
              url
              uncroppedImage{
                srcset
                src
                srcWebp
                srcsetWebp
                placeholderSilhouette
                placeholderImage
                placeholderWidth
                placeholderHeight
                placeholderBox
                placeholderSvg
              }
            }
          }
          timeline
          degree
        }
      }
      workExperience{
        ... on workExperience_BlockType{
          employer
          logo {
            ... on about_Asset{
              title
              size
              filename
              url
              uncroppedImage{
                srcset
                src
                srcWebp
                srcsetWebp
                placeholderSilhouette
                placeholderImage
                placeholderWidth
                placeholderHeight
                placeholderBox
                placeholderSvg
              }
            }
          }
          location
          role
          jobDescription
        }
      }
      minorWorkExperience
      skillSetGroups{
        ... on skillSetGroups_BlockType{
          groupName
          skill{
            ... on skill_skill_BlockType{
              skill
            }
          }
        }
      }
    }
    seomatic(asArray: true){
      metaTitleContainer
      metaLinkContainer
      metaScriptContainer
      metaJsonLdContainer
      metaTagContainer
    }
  }
}
`
